html {
  font-size: 16px;
}

p, a {
  font-size: 1rem;
}

body {
  color: $font-color;
  font-family: "brandon-grotesque", sans-serif;
  hyphens: auto;
  font-size: 100%;
}

a {
  text-decoration: none;
}

h1, h2, h3, h4 {
  color: $primary;
  font-family: "brandon-grotesque", sans-serif;
  text-transform: uppercase;
  font-weight: 700;
}


.subline {
  display: block;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 1em;
}






