
.main-nav {

  div.menu {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    transition: all 1s ease-in-out;
    z-index: 90;
    background: $primary;

    &.closed {
      transform: translateX(200vw);
    }

    .standort {
      display: none;
    }
  }

  .kontakt, .termin, .fontSize {
    &.desktop {
      display: none;
    }

    bottom: 0;
    width: 50%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: $darkgrey;
    padding: 1rem;
    background: #fff;
    z-index: 100;
    cursor: pointer;
  }


  .burger {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: $darkgrey;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #fff;
    position: relative;
    z-index: 100;


    span.open, span.close {
      display: none;
      color: #fff;
      opacity: 1;

      &.active {
        display: block;
      }
    }

    .menu {
      display: none;
    }


    .material-icons {
      font-size: 2rem;
    }
  }


  nav.main-menu {
    width: 100%;
    height: 100vh;
    background: $primary;
    display: flex;
    padding-left: 3rem;
    flex-direction: column;
    justify-content: space-evenly;

    .menu-wrapper {
      width: 100%;
    }


    ul.main {
      font-size: 2rem;
      font-weight: 700;
    }

    ul.sub-nav.sub-main {
      display: none;

      &.active {
        display: flex;
      }

    }

    ul {
      list-style-type: none;
      font-size: 1rem;

      li {
        margin: .25rem;

        &.has-sub {
          display: flex;
          align-items: center;
          cursor: pointer;

          img {
            max-width: 20px;
            margin-right: .5rem;
            margin-bottom: 1rem;
            transition: all 500ms ease-in-out;

            &.rotate {
              transform: rotate(180deg);
            }
          }
        }

        p {
          margin-bottom: 0;
        }

        a, p {
          text-transform: uppercase;
          color: #fff;
          text-decoration: none;
          cursor: pointer;

        }

      }

      li.active {
        position: relative;

        .active-item-dot {
          display: block;
        }

        a {
          opacity: 1;
        }
      }

    }
  }

  footer.marke {
    display: none;
  }
}


@include media-breakpoint-up(sm) {
  .main-nav .burger {
    top: 4%;
    right: 12%;
  }
}

@include media-breakpoint-up(md) {

  .main-nav {
    top: 1.6rem;
    right: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    nav.main-menu {
      width: 100vw;
      height: 100vh;
    }

    .menu-wrapper {
      max-width: 60%;
    }

    .menu {
      z-index: 100;
    }

    .kontakt, .fontSize {
      &.desktop {
        display: flex;
      }

      &.mobile {
        display: none;
      }
    }

    nav.main-menu, footer.marke {
      transition: transform 1s ease-in-out;
      position: fixed;
      left: 0;
    }

    nav.main-menu {
      top: 0;
      height: 100vh;
    }

    footer.marke {
      bottom: 0;
      height: 20vh;
    }

    nav.main-menu ul.sub-nav {
      display: flex;
      flex-wrap: wrap;

      li {
        width: 50%;
        margin: 0;
        padding: .25rem;
      }
    }

    footer.marke {
      display: flex;
      justify-content: space-between;
      z-index: 100;
      width: 100%;
      background: $primary;
      padding: 1rem 3rem;
      flex-direction: row;
      height: 20vh;
      border-top: 1px solid #fff;

      .container {
        margin: 0 !important;
        padding: 0 !important;
      }

      .partner, .logo {
        width: 50%;
        border: none;
        box-shadow: none;
        max-width: 100%;
      }

      .partner {
        flex-direction: column;
        justify-content: start;
        align-items: flex-start;
        color: #fff;

        p {
          margin-bottom: .25rem;
        }

      }
    }

    .fontSize {
      cursor: default;
      display: flex;
      align-items: center;
      font-size: 24px;

      span {
        cursor: pointer;
      }
    }

    .kontakt, .termin, .burger, .fontSize {
      position: static;
      margin-left: 1rem;
      box-shadow: 0 0 10px #00000045 !important;
      padding: .5rem;
      border-radius: 10px;
      width: auto;
      transition: all 500ms ease-in-out;
      z-index: 100;

      .material-icons {
        font-size: 2rem;
      }

      .text {
        display: none;
      }

      .menu {
        display: block;
        text-transform: uppercase;
        margin-right: .5rem;
        font-size: 18px;
      }

      &:hover {
        background: $darkgrey;
        color: #fff;
      }

    }
  }
}

@include media-breakpoint-up(md) {
  .main-nav div.menu nav.main-menu ul.main {
    li a, li p {
      font-size: 50px;
    }

  }
}

@include media-breakpoint-up(lg) {
  .main-nav {

    div.menu {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      padding: 6rem 6% 0 3rem;

      .standort-information {
        display: block;
        max-width: 50%;
        box-shadow: none;
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        font-size: 18px;

        h4 {
          hyphens: auto;
        }

        p {
          margin-bottom: 0;

          a {
            color: #fff;
            text-decoration: underline;
          }
        }

        img {
          margin-bottom: 1rem;
        }
      }

      nav.main-menu {
        position: static;
        height: fit-content;
        width: 100%;

        ul.main {
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          li a, li p {
            font-size: 50px;
          }
        }

        ul.sub-nav {
          padding-top: 1rem;
          flex-wrap: nowrap;
          justify-content: flex-end;

          li {
            width: auto;
            margin: 0 .25rem;

            &:last-child {
              margin-right: 0;
            }

            &.active .active-item-dot {
              transform: translateX(-50%);
              left: 50%;
              top: -9px;
            }
          }
        }
      }

      footer.marke {
        position: static;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-self: flex-end;

        .container {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-direction: row;

          &:last-child {
            justify-content: flex-end;
          }
        }
      }
    }
  }
}
