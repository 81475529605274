// Nav-Header
.menu-header {
    width: 100%;
    z-index: 1000;

    .logo img {
        width: min(300px, 50vw);
    }
}


// Page-Header
section.header {
    padding: 0 0 1rem 0;

    .arrow-down {
        display: none;
    }


    .header-wrapper {
        margin-bottom: 3rem;

        .row {
            min-height: 300px;
        }

        .video {
            display: none;
        }

        div.data {
            display: flex;
            align-items: center;
            justify-content: center;

            .wrapper {
                width: fit-content;

                .mb-3 p {
                    margin-bottom: 0;
                }


                .c2a {
                    display: none;
                }

                a {
                    text-decoration: underline;
                }

                span {
                    margin-right: .5rem;
                }
            }
        }
    }
}

@include media-breakpoint-up(md) {
    section.header {
        .header-wrapper {
            div.data .wrapper {
                .headline {
                    width: 100%;
                }

                font-size: 16px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-between;


                .c2a {
                    margin-top: 1rem;
                    display: block;
                }
            }
        }
    }

}

@include media-breakpoint-down(lg) {
    section.header {
        padding: 1rem 0 0 0;

        .header-wrapper {
            margin-right: var(--bs-gutter-x, -0.75rem);
            margin-left: var(--bs-gutter-x, -0.75rem);

        }
    }
}

@include media-breakpoint-up(lg) {
    body.home {
        header {
            &.mb-3 {
                margin-bottom: 0 !important;
            }

            height: 100px;
        }

        section {
            header .arrow-down {
                display: block;

                span {
                    color: $danger;
                    font-size: 24px;
                }
            }

            &.header {
                min-height: 100vh;
                padding: 0;

                .container-lg {
                    height: 100%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    min-height: calc(100vh - 100px);
                }

                .header-wrapper {
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 50vw;
                    height: 100vh;
                    display: flex;
                    align-items: stretch;

                    .video {
                        display: block;
                    }

                    .fullscreen .item {

                        height: 50vh;
                    }
                }
            }

            //&.header.fullscreen {
            //    display: flex;
            //    flex-direction: row-reverse;
            //    align-items: stretch;
            //    justify-content: space-between;
            //    padding: 0;
            //    min-height: 100vh;
            //
            //    .header-wrapper, .content {
            //        min-height: 100vh;
            //        width: 50%;
            //        font-size: 24px;
            //        line-height: 1.5em;
            //    }
            //
            //    .container .container {
            //        padding: 3rem;
            //        display: flex;
            //        flex-direction: column;
            //
            //    }
            //
            //    .content .content {
            //        width: 100%;
            //        display: flex;
            //        padding: 0 2rem;
            //        flex-direction: column;
            //        justify-content: center;
            //
            //        h1 {
            //            font-size: 55px;
            //            margin-bottom: 2rem;
            //        }
            //
            //    }
            //
            //    .header-wrapper {
            //        .row {
            //            min-height: 100vh;
            //        }
            //    }
            //}
        }
    }

    .medizin-header {
        padding: 0;

        .fullscreen img {
            max-height: 350px;
        }

        section.header {
            padding: 0;
        }

        section {
            padding: 1rem;
        }
    }

    @include media-breakpoint-up(lg) {
        .medizin-header {
            display: grid;
            grid-gap: 1rem;
            grid-template-rows: 50px 1fr;
            grid-template-columns: 1fr 1fr;

            .fullscreen img {
                min-height: 500px;
            }


            .header {
                grid-column: 2/3;
                grid-row: 1/-1;
                align-self: center;
            }

            .breadcrumps {
                grid-column: 1/2;
                grid-row: 1/2;
            }

            .intro {
                grid-column: 1/2;
                grid-row: 2/3;
            }
        }
    }
}

