// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Reset
@import "reset";

// Typo
@import "fonts";

// Helper
@import "helperClasses";

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Components
@import 'Components/footer';
@import "Components/menu";
@import "Components/header";
@import "Components/imgGrid";
@import "Components/iconList";
@import "Components/accordion";
@import "Components/service";
@import "Components/tiles";

// Pages
@import "Pages/home";
@import "Pages/standort";
@import "Pages/serviceDashboard";


.breadcrumps a {
  text-decoration: none;
}

