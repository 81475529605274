* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
    overflow-x: hidden;
}

img, video, svg {
    width: 100%;
    height: 100%;
    object-fit: cover;

    &.contain {
        object-fit: contain;
    }
}

section {
    padding: 3rem 1rem;

    .col-4, .col-12 {
        height: fit-content;
    }

}

