.imgGrid {
  //Layout Startseite
  &.layout-01 {
    display: flex;
    flex-wrap: wrap;
    margin: 3rem -.5rem;

    .imgGrid-item {
      position: relative;
      padding: .5rem;

      &:nth-child(1) {
        width: 80%;
      }

      &:nth-child(2), &:nth-child(3) {
        width: 20%;
      }

      &:nth-child(4) {
        width: 80%;
      }

      &.SoM, &.link {
        display: flex;
        flex-direction: column;

        img {
          margin-bottom: .5rem;
          height: 14vw;
        }
      }

      .link {
        position: absolute;
        width: 100vw;
        text-align: right;
        bottom: -25px;
        padding-right: 4rem;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .imgGrid {

    &.layout-01 {
      margin: 1rem 0;

      display: grid;
      justify-items: stretch;
      align-items: stretch;
      grid-template-columns: repeat(5, 1fr);
      grid-template-rows: 50px 1fr 1fr;
      grid-gap: 1rem;

      .imgGrid-item {
        padding: 0;

        &:nth-child(1) {
          grid-column: 1/3;
          grid-row: 1/3;
          width: 100%;

        }

        &:nth-child(2) {
          grid-column: -2/-1;
          grid-row: 1/2;
          width: 100%;

        }

        &:nth-child(3) {
          grid-column: 1/3;
          grid-row: 3/4;
          width: 100%;

        }

        &:nth-child(3) {
          height: fit-content;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: flex-start;
          justify-content: space-between;

          .link {
            width: 100%;
          }

          img {
            width: 46%;
            max-height: 120px;


          }
        }

        &:nth-child(4) {
          grid-column: 3/-1;
          grid-row: 1/-1;
          width: 100%;

        }


        &.SoM {
          flex-direction: row;
          justify-content: flex-end;
          align-items: flex-end;

          img {
            width: 50px;
            height: 50px;
            margin-bottom: 0;
            margin-left: .5rem;
          }
        }

        &.link {
          position: static;

          .link {
            position: static;
            padding-right: 0;
            width: auto;
          }
        }
      }
    }
  }
}
