.icon-list-wrapper {
    .icon-list-icon {
        display: none;
    }

    .icon-list {
        list-style-type: none;
    }

    .icon-list-item {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: flex-end;
        margin: .5rem 0;
        font-weight: 500;

        a {
            color: inherit;
        }

        .icon {
            margin-right: 1.5rem;
            max-width: 20px;
            max-height: 20px;
            object-fit: contain;
        }
    }
}

@include media-breakpoint-up(md) {
    .icon-list-wrapper {
        .icon-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .icon-list-item {
                width: 50%;
                padding: 1rem 0;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .icon-list-wrapper {
        .icon-list {
            flex-direction: column;

            .icon-list-item {
                width: 100%;
                padding: .1rem 0;
            }

        }
    }
}


