.tile-wrapper {
  align-items: stretch;

  a.tile {
    height: 100%;
    transition: all 500ms ease-in-out;
    text-decoration: none;

    .wrapper h3 {
      text-decoration: none;
    }

    .wrapper, svg .cls-1, h3 {
      transition: all 500ms ease-in-out;
    }

    .tile-content {
      .svg-wrapper {
        text-align: center;

        svg {
          width: min(80%, 180px);
          max-height: 100px;
        }
      }
    }
  }

  //TODO optional hover effect
  a.tile:hover {
    .wrapper {
      &.bg-primary-light {
        background: $primary;
      }

      h3 {
        color: $white !important;
      }

      svg {
        .cls-1 {
          fill: $white !important;
        }
      }
    }
  }
}

