footer {
  img {
    object-fit: contain;
    width: min(250px, 100%);
    max-width: 100%;
  }

  nav ul {
    list-style-type: none;

    li a {
      color: $white;
      padding: 1rem;
      text-transform: uppercase;
      text-decoration: none;
    }
  }
}


