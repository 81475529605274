.bg-primary-light {
  background: $primary-light;
}

.border-top-1 {
  border-top: 1px solid $font-color;
}

.subline {
  display: block;
  font-weight: lighter;
}

.w-80 {
  width: 80%;
}

.w-50 {
  width: 50%;
}

.image.is-square {
  position: relative;
  overflow: hidden;
  padding-bottom: 100%;

  img {
    position: absolute;
  }
}

.contain, .contain img {
  object-fit: contain !important;
}

.text-primary-50 {
  color: $primary-dark !important;
}

.no-break {
  hyphens: none;
}
